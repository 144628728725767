<template>
  <div class="text-center overflow-x-scroll">
    <b-button-group
      class="mb-2"
    >
      <b-button
        :variant="this.$route.name == 'StartupReport' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/girisim-raporu/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BarChartIcon" />
        {{ $t('Girişim Raporu') }}
      </b-button>
      <b-button
        :variant="this.$route.name == 'MarketAnalysis' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/pazar-analizi-raporu/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="CrosshairIcon" />
        {{ $t('Pazar Analizi Raporu') }}
      </b-button>
      <b-button
        :variant="this.$route.name == 'BusinessModelAnalysis' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/is-modeli-performansi/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BarChart2Icon" />
        {{ $t('İş Modeli Performansı') }}
      </b-button>
      <b-button
        :variant="this.$route.name == 'Canva' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/is-modeli-kanvasi/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BoxIcon" />
        {{ $t('İş Modeli Kanvası') }}
      </b-button>
      <b-button
        :variant="this.$route.name == 'TechnologyReadiness' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/teknoloji-hazirlik-seviyesi/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="GlobeIcon" />
        {{ $t('Teknoloji Hazırlık Seviyesi') }}
      </b-button>
      <b-button
        :variant="this.$route.name == 'Sdg' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/etki-analizi/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="BarChart2Icon" />
        {{ $t('Etki Analizi') }}
      </b-button>
      <b-button
        :variant="this.$route.name == 'InvestorSummaryReport' ? 'primary' : 'outline-primary'"
        :to="'/raporlar/yatirimci-ozet-sayfasi/' + this.$route.params.id"
        class="text-nowrap"
      >
        <FeatherIcon icon="PieChartIcon" />
        {{ $t('Yatırımcı Özet Sayfası') }}
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import {
  BButtonGroup, BButton,
} from 'bootstrap-vue'

export default {
  name: 'RouteButtons',
  components: {
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
}
</script>
